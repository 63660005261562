import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import { kebabCase } from 'lodash'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const tag = this.props.pageContext.tag
    const title = this.props.data.site.siteMetadata.title
    const totalCount = this.props.data.allMarkdownRemark.totalCount
    const featuredpost = this.props.data.allMarkdownRemark.edges
    const tagHeader = `${totalCount} post${
      totalCount === 1 ? '' : 's'
    } tagged with “${tag}”`

    return (
      <Layout>
        <main className="l-main">
          <Helmet title={`${tag} に関するブログ | ${title}`} />
          <div className="l-container">
            <h1 className="c-pageTitle" data-spacingsp="mb7">
              <span className="c-pageTitle__container">
                <span className="c-pageTitle__textJa">ブログ #{`${tag}`}</span>
                <span className="c-pageTitle__textEn">BLOG</span>
              </span>
            </h1>
          </div>
          <div className="l-container--wide">
            <ul className="p-breadCrumbs">
              <li className="p-breadCrumbs__item"><Link to="/" className="p-breadCrumbs__link">ホーム</Link></li>
              <li className="p-breadCrumbs__item"><Link to="/blog" className="p-breadCrumbs__link">ブログ</Link></li>
              <li className="p-breadCrumbs__item isCurrent">#{`${tag}`}</li>
            </ul>
          </div>
          <div className="l-container">
            <div className="p-blogList">
              {posts &&
                posts.map(({ node: post }) => (
                  <div className="p-blogList__item" key={post.id}>
                    <article className={`p-blogList__container ${featuredpost  ? ( 'c-featured') : null }`}>
                      <Link to={post.fields.slug}>
                        {post.frontmatter.featuredimage ? (
                          <figure className="p-blogList__figure">
                            <PreviewCompatibleImage
                              imageInfo={{
                                image: post.frontmatter.featuredimage,
                                alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                              }}
                            />
                          </figure>
                        ) : (
                          <figure className="p-blogList__figure--noThumbnail">
                            <img className="p-blogList__img" src="/img/blog/nothumbnail.png" alt="no thumbnail" />
                          </figure>
                        )}
                      </Link>
                      <div className="p-blogList__body">
                        <Link className="p-blogList__title" to={post.fields.slug}>
                          {post.frontmatter.title}
                          {/* <span className="p-blogList__date">
                                {post.frontmatter.date}
                              </span> */}
                        </Link>
                        {post.frontmatter.tags && post.frontmatter.tags.length ? (
                          <ul className="p-blogList__tags">
                            {post.frontmatter.tags.map(tag => (
                              <li className="c-tagButton p-blogList__tag" key={tag + `tag`}>
                                <Link className="c-tagButton__link" to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                              </li>
                            ))}
                          </ul>
                        ) : null}
                      </div>
                    </article>
                  </div>
                ))}
            </div>
          </div>
        </main>
      </Layout>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            tags
            templateKey
            featuredpost
            date(formatString: "Y.m.d")
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 335, maxHeight: 223, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
